@import '../../style/sass/main.scss';

.set-new-password-container {
    width: 100%;
    height: 100vh;
}

.set-new-passsword {
    background-image: url(../../assets/login-bg.jpg);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo-container {
        .logo-container-logo {
            margin-bottom: 2.88rem;
        }

        .logo-img {
            height: 10.13rem;
            width: 10.5rem;
            object-fit: contain;
        }
    }


    .set-new-passsword-form {
        width: 43.75rem;
        display: flex;
        flex-direction: column;
        border: 0.3125rem solid rgba($color: #E5E8EC, $alpha: 1);
        border-radius: 0.625rem;
        padding: 1.6rem 3.92rem;
        background: $white-color;

        @media only screen and (max-width: 43.75rem) {
            padding: 1rem 1rem;
            width: 100%;
        }
    }

    .set-new-passsword-input {
        position: relative;
        width: 100%;
        margin-bottom: 1rem;
        font-family: "Montserrat", sans-serif;
        display: flex;
        flex-direction: column;

        .input-check-icon {
            position: absolute;
            color: $status-green;
            right: -2rem;
            top: 1rem;
        }

        .input-check-icon-disabled {
            color: $grey-1;
        }
    }

    .set-new-passsword-disclaimer {
        margin-top: 0.625rem;
        color: $grey-2;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    .set-new-passsword-heading {
        margin-bottom: 1rem;
        color: $primary-color;
        font-size: 2rem;
        font-weight: 500;
        font-family: "poppins", sans-serif;
    }

    .set-new-passsword-subheading {
        margin-bottom: 3rem;
        font-size: 1.25rem;
        color: $text-color-2;
        font-weight: 400;
        line-height: 1.25rem;
    }

    .set-new-passsword-back {
        width: 100%;
        color: $grey-1;
        padding-top: 1.875rem;
        @include flexCenter;
        font-size: 1rem;
        font-weight: 600;

        span {
            margin-left: 0.625rem;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .set-new-passsword-button {
        background: $primary-color;
        color: $white-color;
        padding: 1.1rem 0;
        font-size: 1.25rem;
        font-weight: 700;
        border-radius: 0.625rem;
        border: none;
        // font-family: ;
    }

    .set-new-passsword-button-disabled {
        background: $grey-1 !important;

        &:hover {
            cursor: not-allowed;
        }
    }
}