@import "../../style/sass/main.scss";

.forgot-password-container {
  width: 100%;
  height: 100vh;
}

.forgot-password {
  background-image: url(../../assets/login-bg.jpg);
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo-container {
    .logo-container-logo {
      margin-bottom: 2.88rem;
    }

    .logo-img {
      height: 10.13rem;
      width: 10.5rem;
      object-fit: contain;
    }
  }

  .forgot-password-form {
    width: 43.75rem;
    display: flex;
    flex-direction: column;
    border: 0.3125rem solid rgba($color: #e5e8ec, $alpha: 1);
    border-radius: 0.625rem;
    padding: 2.8rem 3.92rem;
    background: $white-color;

    @media only screen and (max-width: 43.75rem) {
      padding: 1rem 1rem;
      width: 100%;
    }
  }

  .forgot-password-input {
    margin-bottom: 2rem;
    font-family: "Montserrat", sans-serif;
  }

  .forgot-password-heading {
    margin-bottom: 1rem;
    color: $secondary-color;
    font-size: 2rem;
    font-weight: 500;
    font-family: "poppins", sans-serif;
  }

  .forgot-password-subheading {
    margin-bottom: 3rem;
    font-size: 1.25rem;
    color: $text-color;
    font-weight: 500;
  }

  .forgot-password-back {
    width: 100%;
    // color: $grey-1;
    padding: 1.45rem 0;
    @include flexCenter;
    font-size: 1rem;
    font-weight: 600;

    span {
      margin-left: 0.625rem;
    }
  }

  .change-password-button {
    background: $primary-color;
    color: $white-color;
    border: none;
  }

  .forgot-password-button {
    border-radius: 0.625rem;
    background: $primary-color;
    color: $white-color;
    padding: 1.1rem 0;
    font-size: 1.25rem;
    font-weight: 700;
    text-align: center;
  }
}