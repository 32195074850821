@import "./sass/main.scss";

.notification-dialog {
  .title {
    color: $secondary-color;
    font-family: Montserrat, sans-serif;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.2;
  }

  .sub-title {
    color: $primary-color;
    font-weight: 500;
    margin-top: 0.5rem;
  }

  .dialog-action {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .view-all {
      color: $secondary-color;
      font-family: Montserrat, sans-serif;
      font-weight: 500;
      text-transform: initial;
      font-size: 1.2rem;
    }
  }

  .cards {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding-bottom: 0.5rem;
    }

    padding: 0.5rem 0;

    .badge {
      margin-top: 0.6rem;
    }

    .title-card {
      color: $secondary-color;
      font-family: Montserrat, sans-serif;
      font-size: 1rem;
      font-weight: 600;
      max-height: 2.625rem;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.2;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .sub-title {
      font-size: 1rem;
      color: $light-grey;
      font-family: Montserrat, sans-serif;
      font-weight: 600;
      text-align: right;
      margin: 0.2rem 0;
    }

    .read {
      font-weight: 400;
    }
  }
}

.alert-dialog-main {
  top: 2.5rem !important;
  right: 0 !important;
  left: unset !important;
  bottom: unset !important;

  .MuiPaper-root {
    width: 35rem;
  }

  .no-record {
    font-family: Montserrat, sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    text-transform: initial;
    color: $secondary-color;
    text-align: center;
  }
}

.alert-dialog {
  .MuiPaper-root {
    width: 70rem;
  }

  .no-record {
    font-family: Montserrat, sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    text-transform: initial;
    color: $secondary-color;
    text-align: center;
  }

  .sticky-input-select {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1;
    position: sticky;
    background: #fff;
    z-index: 1;
    width: 100%;
    top: 0;
    height: 5rem;
  }
}

.action-show-all-dialog {
  .view-all {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    text-transform: initial;
    font-size: 1.2rem;
  }
}

.table-container {
  overflow-x: scroll;

  .table-notification {
    border-collapse: collapse;
    display: table;
    height: auto;
    overflow-x: auto;
    width: 100%;

    tr {
      th {
        span {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.5rem;
          cursor: pointer;
        }
      }
    }

    th,
    td {
      word-break: break-word;
      white-space: nowrap;
      padding: 0.6rem 1.3125rem;
      border: 0.125rem solid $secondary-color;
      font-family: Montserrat, sans-serif;
    }

    th {
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.5rem;
      color: $secondary-color;
      background: $table-header;
      text-align: center;
      vertical-align: middle;
    }

    td {
      color: $table-td-color;
      font-size: 1.1rem;
      line-height: 1.25rem;
    }
  }
}