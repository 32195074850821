// Logo Styling
.logo-3,
.logo-sc {
    font-size: 8rem;
    line-height: 13rem;
    font-weight: 700;
    // font-family: 'Montserrat';
}

.logo-3 {
    color: $primary-color;
}

.logo-sc {
    color: $secondary-color;
}

.remove-underline {
    text-decoration: none;
}

.w-100 {
    width: 100%;
}

.common-heading {
    color: $primary-color;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
}

.section-heading {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: $primary-color;
}

.section-subheading {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    color: $text-disabled;
}

.grey-heading-500,
.grey-heading-600 {
    color: $grey-2;
    font-size: 1rem;
    line-height: 1.25rem
}

.grey-heading-500 {
    font-weight: 500;
}

.grey-heading-600 {
    font-weight: 600;
}

.section-breadcrumbs {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 400;

    .active {
        color: $primary-color;
    }
}

.section-breadcrumbs-a {
    color: $text-disabled;

    &:hover {
        color: $primary-color;
    }
}


// Mui Text Field Global Styling
fieldset.MuiOutlinedInput-notchedOutline {
    border-color: $grey-1  !important;
}

label.MuiInputLabel-root,
.MuiInputLabel-root.MuiInputLabel-formControl.Mui-focused {
    color: $grey-1;
    z-index: 0;
}

.primary-button {
    background: $primary-color;
    color: $white-color;
    border-radius: 0.625rem;
    padding: 0.9375rem 1.1875rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;

    &:hover {
        color: $white-color;
    }
}

.secondary-button {
    background: $link-color-1;
    color: $primary-color;
    font-size: 1rem;
    line-height: 1.25rem;
    border-radius: 10px;
    padding: 1rem;
    font-weight: 600;
}

.cancel-button {
    border: 1px solid $primary-color;
    padding: 0.9375rem 1.375rem;
    color: $primary-color;
    line-height: 1.25rem;
    font-weight: 600;
    border-radius: 0.625rem;
}

.ml-20 {
    margin-left: 1.25rem !important; //20px
}

.ml-10 {
    margin-left: 0.625rem !important; //10px
}

.k-grid-header.k-header {
    color: $white-color;
    background: $primary-color;
    border: 1px solid #EDEDED;
    padding: 28px 21px;
}