@import '../../../style/sass/main.scss';

.main-container {
    height: 100vh;

    .layout-content-main {
        height: 100%;
        width: 100%;
        background: $white-color;
        padding-top: 3.75rem;
        // padding: 1.125rem;
        // border: 1px solid $grey-3;
    }

    .layout-content-main-app-scroll {
        overflow: scroll;
    }

    .layout-content-main {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        background: $white-color;
        padding-top: 5.75rem;
        // padding: 1.125rem;
        // border: 1px solid $grey-3;
        // mask-image: linear-gradient(to top, transparent, black),
        //     linear-gradient(to left, transparent 17px, black 17px);
        // mask-size: 100% 20000px;
        // mask-position: left bottom;
        // -webkit-mask-image: linear-gradient(to top, transparent, black),
        //     linear-gradient(to left, transparent 17px, black 17px);
        // -webkit-mask-size: 100% 20000px;
        // -webkit-mask-position: left bottom;
        transition: mask-position 0.3s, -webkit-mask-position 0.3s;

        &:hover {
            -webkit-mask-position: left top;
        }

    }

    .layout-content-sidebar {
        width: 100%;
        height: 100%;
    }

    .border-body {

        .border-body-main-menu {
            text-align: center;
            position: absolute;
            background: $white-color;
            padding: 1rem;
            margin: 1rem 1rem 1rem 0.5rem;
            top: -2.6rem;
            left: 0;
        }

        .border-menu {
            background: $border-color;
            padding: 1rem;
            color: $primary-color;
            margin-right: 0.75rem;
            font-size: 1.10rem;
            font-weight: 600;
            border-radius: 0.5rem;
            @include cursorPointer;
        }

        .border-menu-active {
            background: $primary-color;
            color: $white-color;
        }
    }
}

.layout-content-main-app-scroll {
    overflow: scroll;
    // padding: 1.125rem;
}

.layout-content-main-app {
    min-height: calc(100vh - 64px);
    padding: 0rem 0.1rem !important;
}