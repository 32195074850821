.input-field {

  .disabled {
    &:hover {
      cursor: not-allowed;
    }
  }

  input,
  textarea {
    background: none;
    color: black;
    font-size: 1rem;
    padding: 0 1.6rem;
    display: block;
    width: 100%;
    border-radius: 0.5rem;
    border: 2px solid rgba(0, 0, 0, 0.15);
    line-height: 3.2rem;
  }


  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    // border: 1px solid white;
    // -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 62.5rem #fff inset;
    transition: background-color 5000s ease-in-out 0s;

    &~label {
      top: -1.16rem;
      font-size: 1rem;
      color: #939598;
    }
  }


  input:focus,
  textarea:focus {
    outline: none;
  }

  input:focus~label,
  input:valid~label,
  textarea:focus~label,
  textarea:valid~label {
    top: -1.16rem;
    font-size: 1rem;
    color: #939598;
  }

  label {
    color: #939598;
    font-size: 1rem;
    position: absolute;
    pointer-events: none;
    left: 1.25rem;
    top: 0.8rem;
    transition: 300ms ease all;
    background-color: #FFF;
    padding: 0.416rem 0.833rem;
    font-weight: 500;
  }

  .bar {
    position: relative;
    display: block;
  }

  .bar:before {
    content: "";
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: #292666;
    transition: 300ms ease all;
    left: 0%;
  }

  .group {
    position: relative;
  }

  .text-body {
    color: #939598 !important;
    font-weight: 500;
  }

  .width100 {
    width: 100%;
  }


  .group i {
    position: absolute;
    top: 1.33rem;
    right: 1.66rem;
  }

  .group {
    svg {
      width: 2rem;
      height: 2rem;
      position: absolute;
      z-index: 9;
      top: 0.7rem;
      right: 1.5rem;
    }

    span {
      top: 0.1rem;
      right: 0rem;
      position: absolute;
      display: block;
      cursor: pointer;

      svg {
        position: inherit;
      }
    }

  }

  .login-box h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 2.6rem;
    line-height: 3.33rem;
    color: #292666;
  }

  .login-box p {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 2rem;
    color: #939598;
    margin-bottom: 2.92rem;
  }

  .login-box a:hover {
    color: #292666 !important;
  }

  .login-box .group span {
    font-size: 1rem;
    margin: 0;
    position: absolute;
    bottom: -1.6rem;
    color: #D1D1D1;
  }

  .login-box p a {
    color: #292666;
  }

  @media only screen and (max-width : 53.3rem) {
    .group i {
      right: 1.66rem;
      top: 0;
      line-height: 5.1rem;
    }
  }

  .helper-text {
    min-height: 1.25rem;
    margin: 0.2rem 0 0.5rem 0;
  }
}

.input-email-address, .input-password {
  margin-bottom: 0.5rem;
  flex-basis: auto !important;
}