@import "../../style/sass/main.scss";

.login-container {
  width: 100%;
  height: 100vh;
}

.login {
  background-image: url(../../assets/login-bg.jpg);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo-container {
    .logo-container-logo {
      margin-bottom: 2.88rem;
    }

    .logo-img {
      height: 10.13rem;
      width: 10.5rem;
      object-fit: contain;
    }
  }

  .login-form {
    width: 43.75rem;
    display: flex;
    flex-direction: column;
    border: 0.3125rem solid rgba($color: #e5e8ec, $alpha: 1);
    border-radius: 0.625rem;
    padding: 2.5rem 3rem;
    background: $white-color;

    @media only screen and (max-width: 43.75rem) {
      padding: 1rem 1rem;
      width: 100%;
    }
  }

  .login-input {
    margin-bottom: 2rem;
    font-family: "Montserrat", sans-serif;

    .MuiInputBase-input.MuiInputBase-inputAdornedEnd {
      padding: 1rem 1.16rem;
    }
  }

  .login-heading {
    margin-bottom: 0.5rem;
    color: $secondary-color;
    font-size: 2rem;
    font-weight: 500;
    font-family: "poppins", sans-serif;
  }

  .login-subheading {
    margin-bottom: 3rem;
    font-size: 1.25rem;
    color: $text-color;
    font-weight: 400;
    font-family: "poppins", sans-serif;
  }

  .login-forgot {
    width: 100%;
    padding: 0 0 1.45rem 0;
    text-align: right;

    .login-forgot-link {
      // color: $secondary-color;
      font-weight: 500;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .login-button {
    background: $primary-color;
    color: $white-color;
    padding: 1.1rem 0;
    font-size: 1.25rem;
    font-weight: 700;
    border-radius: 0.625rem;
    border: none;
    width: 100%;
    line-height: 1.385rem;
  }

  .button-seperator {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1rem;
    margin: 0.8rem;
  }
}

.otp-input-container {
  margin: 2rem 0;

  .otp-input {
    border: 0.1px solid;
    border-radius: 0.8rem;
    width: 5.4rem !important;
    height: 6rem !important;
    font-size: 1.3rem;
    font-weight: 600;
    color: $black-color;
    // caret-color: blue;
    margin: 0rem 0.8rem;

  }
}